var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./mobile.footer.css";
import iconHome from "../../assets/icons/home_icon.svg";
import iconRaffle from "../../assets/icons/raffle_icon.svg";
import iconCart from "../../assets/icons/cart_icon.svg";
import iconGames from "../../assets/icons/games_icon.svg";
import iconProducts from "../../assets/icons/products_icon.svg";
import { cartStore, cartToggleSubject } from "../../store/cart.store";
import { useEffect, useState } from "react";
import { useForceUpdate } from "../../services/misc.functions";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { raffleDrawSubject } from "../../store/games.store";
import { isMobile } from "../../App";
import paths from "../../paths";
import { listGamePages } from "../../constants/global.constants";
var cartItemCount = 0;
var sidebar = null;
var detailBackdrop = null;
var ticket;
var prevUrl = "";
var prevTitle = "";
export function MobileFooter() {
    var navigate = useNavigate();
    var forceUpdate = useForceUpdate();
    var pathname = useLocation().pathname;
    var _a = useState(true), hidden = _a[0], setHidden = _a[1];
    useEffect(function () {
        var cartToggleSubject_subscriber = cartToggleSubject.subscribe(function (res) {
            cartItemCount = cartStore.items.length > 0 ? cartStore.items.map(function (el) { return el.quantity; }).reduce(function (a, b) { return Number(a) + Number(b); }) : 0;
            forceUpdate();
        });
        return function () {
            cartToggleSubject_subscriber.unsubscribe();
        };
    }, []);
    useEffect(function () {
        cartToggleSubject.next({ action: 'visibleToggleCart', show: false });
        document.body.style.overflow = "unset";
    }, [pathname]);
    var navigateTo = function (path) {
        cartToggleSubject.next({ action: 'visibleToggleCart', show: false });
        navigate(path);
    };
    var _b = useState(null), showDetail = _b[0], setShowDetail = _b[1];
    if (showDetail) {
        ticket = showDetail;
    }
    useEffect(function () {
        var raffleDrawSubject_subscriber = raffleDrawSubject.subscribe(function (res) {
            sidebar = document.getElementById("id_detail_wrapper");
            if (res && res.action === 'open') {
            }
            else if (res && res.action === 'history') {
            }
            else {
                closeDetail();
            }
        });
        return function () {
            raffleDrawSubject_subscriber.unsubscribe();
        };
    }, []);
    var closeDetail = function () {
        sidebar = document.getElementById("id_detail_wrapper");
        detailBackdrop = document.getElementById("detail-backdrop");
        if (detailBackdrop) {
            detailBackdrop.classList.remove('show');
        }
        if (sidebar) {
            sidebar.style.bottom = "-600px";
            sidebar.style.visibility = "hidden";
        }
        if (showDetail) {
            document.title = prevTitle;
            window.history.pushState(null, "", "");
        }
        setShowDetail(null);
    };
    return (_jsx(_Fragment, { children: isMobile && !(listGamePages.includes(pathname)) && (_jsxs(_Fragment, { children: [_jsx("div", { className: "mobile-footer-menu-bg" }), _jsxs("div", __assign({ className: "mobile-footer-menu" }, { children: [_jsxs("div", __assign({ className: "col ".concat(pathname === paths.home.url ? 'active' : undefined), onClick: function () {
                                if (!hidden) {
                                    document.body.style.overflow = "unset";
                                }
                                navigateTo(paths.home.url);
                            } }, { children: [_jsx("img", { src: iconHome }), "Anasayfa"] })), _jsxs("div", __assign({ className: "col ".concat(pathname.includes(paths.raffleTickets.url) ? 'active' : undefined), onClick: function () {
                                if (!hidden) {
                                    document.body.style.overflow = "unset";
                                }
                                navigateTo(paths.raffleTickets.url);
                            } }, { children: [_jsx("img", { src: iconRaffle }), "\u00C7ekili\u015Fler"] })), _jsxs("div", __assign({ className: "col cart", onClick: function () {
                                closeDetail();
                                setHidden(!hidden);
                                if (!hidden) {
                                    document.body.style.overflow = "unset";
                                }
                                cartToggleSubject.next({ action: 'visibleToggleCart', show: hidden });
                            } }, { children: [_jsxs("div", { children: [cartItemCount > 0 && _jsx("span", __assign({ className: "badge" }, { children: cartItemCount })), _jsx("img", { src: iconCart })] }), "Sepetim"] })), _jsxs("div", __assign({ className: "col ".concat(pathname.includes(paths.games.url) ? 'active' : undefined), onClick: function () {
                                if (!hidden) {
                                    document.body.style.overflow = "unset";
                                }
                                navigateTo(paths.games.url);
                            } }, { children: [_jsx("img", { src: iconGames }), "Oyunlar"] })), _jsxs("div", __assign({ className: "col ".concat(pathname.includes(paths.products.url) ? 'active' : undefined), onClick: function () {
                                if (!hidden) {
                                    document.body.style.overflow = "unset";
                                }
                                navigateTo(paths.products.url);
                            } }, { children: [_jsx("img", { src: iconProducts }), "\u00D6d\u00FCller"] }))] }))] })) }));
}
